import http from "../http";
import axios from "axios";

/**
 * 产品库存规则维护
 * 分页查询异常处理调拨单和子项
 */
export const inventoryRulesList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL + `productinventoryrule/v1/productinventoryrules/search?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 删除产品库存规则表的一行
 */
export const deleteInventoryItem = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_INVENTORY_URL + `productinventoryrule/v1/productinventoryrules/${id}`,
  });
};

/**
 * 根据id查询产品库存规则表
 */
export const searchInventoryItem = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INVENTORY_URL + `productinventoryrule/v1/productinventoryrules/${id}`,
  });
};

/**
 * 查询常量数据字典
 */
export const inventoryCountList = (dictIndex) => {
  if (dictIndex == 'MaterialInventoryType') {
    console.log('constDictList,MaterialInventoryType');
    let res ={}
    res.data = [
      {
          "id": 123,
          "rev": 1,
          "createTime": "2020-02-16T04:04:58.000+0000",
          "updateTime": "2020-02-16T04:05:01.000+0000",
          "dictIndex": "MaterialInventoryType",
          "dictItemValue": "A",
          "dictItemName": "本地仓所有库存",
          "dictItemOrder": 1,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 124,
          "rev": 1,
          "createTime": "2020-02-16T04:09:35.000+0000",
          "updateTime": "2020-02-16T04:09:38.000+0000",
          "dictIndex": "MaterialInventoryType",
          "dictItemValue": "L",
          "dictItemName": "零剪仓所有库存",
          "dictItemOrder": 2,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 125,
          "rev": 1,
          "createTime": "2020-02-16T04:10:24.000+0000",
          "updateTime": "2020-02-16T04:10:28.000+0000",
          "dictIndex": "MaterialInventoryType",
          "dictItemValue": "Z",
          "dictItemName": "职业装仓所有库存",
          "dictItemOrder": 3,
          "remark": null,
          "dictItemLanguage": "CH"
      }
  ]
    res.code = '0'
    return res
  }
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/dicts?dictIndex=${dictIndex}`,
  // });
};

/**
 * 规则公式
 */
export const searchRuleFormula = (productCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INVENTORY_URL + `productinventoryrule/v1/ruleexpression/search?productCode=${productCode}`,
  });
};

/**
 * 新增产品库存规则表
 */
export const addInventoryRules = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_INVENTORY_URL + `productinventoryrule/v1/productinventoryrules`,
    data
  });
};

/**
 * 根据产品编号搜索物料编号
 */
export const searchMaterialCode = (productCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_PRODUCT_URL + `productmaterial/v1/productmaterial/getMaterialCodeByProductCode?productCode=${productCode}`,
  });
};

/**
 * 修改产品库存规则表
 */
export const modifyInventoryRules = (id, data) => {
  return http({
    method: "put",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_INVENTORY_URL+ `productinventoryrule/v1/productinventoryrules/${id}`,
    data
  });
};

