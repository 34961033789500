var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container fabricMaintain" },
    [
      !_vm.isEdit
        ? _c("div", [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("物料编号组：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.materialCode,
                        callback: function($$v) {
                          _vm.materialCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "materialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("产品编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.productCode,
                        callback: function($$v) {
                          _vm.productCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "productCode"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c("ul", { staticClass: "formula_wrap mb10" }, [
                  _c(
                    "li",
                    { staticClass: "w10p", attrs: { title: "默认公式" } },
                    [_vm._v("默认公式")]
                  ),
                  _c("li", { staticClass: "w30p" }, [
                    _vm._v(
                      "产品在库库存 = " +
                        _vm._s(
                          _vm.defaultRuleFormulaName
                            ? _vm.defaultRuleFormulaName
                            : "100% × 物料在库库存"
                        )
                    )
                  ]),
                  _c(
                    "li",
                    {
                      staticClass: "w40p",
                      attrs: {
                        title:
                          "备注：如果不添加特殊公式，所有产品库存按照默认公式计算，以下列表展示的为特殊添加的产品规则列表"
                      }
                    },
                    [
                      _vm._v(
                        "备注：如果不添加特殊公式，所有产品库存按照默认公式计算，以下列表展示的为特殊添加的产品规则列表"
                      )
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "w20p blue",
                      attrs: { title: "默认公式编辑" },
                      on: { click: _vm.formulaEditHandle }
                    },
                    [_vm._v("默认公式编辑")]
                  )
                ]),
                _c(
                  "el-tabs",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableLoading,
                        expression: "tableLoading"
                      }
                    ],
                    staticClass: "subnav_wrap",
                    staticStyle: { "min-height": "536px" }
                  },
                  [
                    _c("singleTable", {
                      attrs: {
                        tableList: _vm.inventoryList,
                        tableLeaderFieldsList: _vm.leaderFieldsList,
                        tableTailFieldsList: _vm.tailFieldsList,
                        tableFieldsList: _vm.fieldsList
                      },
                      on: {
                        deleteHandle: _vm.deleteHandle,
                        editHandle: _vm.editHandle,
                        checkHandle: _vm.checkHandle
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: this.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "form_part clearfix" }, [
            _c("div", { staticClass: "edit_wrap mt10" }, [
              _vm.isShowInputGroup
                ? _c(
                    "div",
                    { staticClass: "input_group group clearfix" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-ruleForm fl",
                          attrs: {
                            model: _vm.ruleForm,
                            rules: _vm.rules,
                            "label-width": "200px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品编号", prop: "productCode" }
                            },
                            [
                              _c("el-input", {
                                on: { change: _vm.productCodeChange },
                                model: {
                                  value: _vm.ruleForm.productCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "productCode", $$v)
                                  },
                                  expression: "ruleForm.productCode"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "物料编号", prop: "materialCode" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.materialCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "materialCode", $$v)
                                  },
                                  expression: "ruleForm.materialCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "formula_group group clearfix" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-ruleForm fl",
                      attrs: { "label-width": "200px" }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "规则" } },
                        [
                          _vm.isCustomizeFormula
                            ? _c(
                                "el-form",
                                {
                                  ref: "ruleForm",
                                  attrs: {
                                    model: _vm.ruleForm,
                                    rules: _vm.rules,
                                    "label-position": "top",
                                    "label-width": "200px"
                                  }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "display_ib",
                                      attrs: {
                                        label:
                                          "变量：Z（职业装仓所有库存）；A（本地仓所有库存）；L（零剪仓所有库存）；",
                                        prop: "ruleFormula"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.ruleForm.ruleFormula,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "ruleFormula",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.ruleFormula"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { staticClass: "display_ib ml10" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: _vm.cancelCustomizeHandle
                                          }
                                        },
                                        [_vm._v("取消自定义")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "el-form",
                                {
                                  ref: "ruleForm",
                                  attrs: {
                                    model: _vm.ruleForm,
                                    rules: _vm.rules,
                                    "label-position": "top",
                                    "label-width": "50px"
                                  }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "display_ib mb10",
                                      attrs: {
                                        label: "系数",
                                        prop: "coefficient"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          onkeyup:
                                            "this.value=this.value.replace(/[^\\d.]/g,'');",
                                          maxlength: "40"
                                        },
                                        model: {
                                          value: _vm.ruleForm.coefficient,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "coefficient",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.coefficient"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v("   x   \n              "),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "display_ib mb10",
                                      attrs: {
                                        label: "物料库存数",
                                        prop: "inventoryCount"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择物料库存数"
                                          },
                                          model: {
                                            value: _vm.ruleForm.inventoryCount,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "inventoryCount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.inventoryCount"
                                          }
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "请选择物料库存数",
                                              value: ""
                                            }
                                          }),
                                          _vm._l(
                                            _vm.inventoryRuleList,
                                            function(item) {
                                              return _c("el-option", {
                                                key: item.dictItemValue,
                                                attrs: {
                                                  label: item.dictItemName,
                                                  value: item.dictItemValue
                                                }
                                              })
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("   +   \n              "),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "display_ib mb10",
                                      attrs: { label: "常量", prop: "constant" }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          onkeyup:
                                            "this.value=this.value.replace(/[^\\d.]/g,'');",
                                          maxlength: "40"
                                        },
                                        model: {
                                          value: _vm.ruleForm.constant,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "constant",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.constant"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { staticClass: "display_ib ml10" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: { click: _vm.customizeHandle }
                                        },
                                        [_vm._v("自定义公式")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "mt20 fr" },
              [
                _c(
                  "el-button",
                  { staticClass: "mr10", on: { click: _vm.cancelHandle } },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.saveHandle("ruleForm")
                      }
                    }
                  },
                  [_vm._v("保存")]
                )
              ],
              1
            )
          ]),
      _c(
        "div",
        { staticClass: "popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.popupTitle,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.dialogFormVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("el-tabs", [
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.checkFieldsList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "allparentlist" },
                  _vm._l(_vm.checkInventoryList, function(item, index) {
                    return _c(
                      "ul",
                      { key: index, staticClass: "childcontentlist" },
                      [
                        _c(
                          "li",
                          { staticClass: "w9p", attrs: { title: index + 1 } },
                          [_vm._v(_vm._s(index + 1))]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w9p",
                            attrs: { title: item.productCode }
                          },
                          [
                            _vm._v(
                              _vm._s(item.productCode ? item.productCode : "无")
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w9p",
                            attrs: { title: item.materialCode }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                item.materialCode ? item.materialCode : "无"
                              )
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w20p",
                            attrs: { title: item.ruleExpressionName }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                item.ruleExpressionName
                                  ? item.ruleExpressionName
                                  : "无"
                              )
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.deleteDialogVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.deleteDialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.deleteDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDeleteHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }